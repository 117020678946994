import { useEffect } from 'react';
import {
    setCallEnded,
    setCallInitiated,
    setConnectionError,
    setWaitingScreen,
} from '../redux-toolkit/reducer/teamsReducer';
import { useDispatch, useSelector } from 'react-redux';

export default function useCmdTeamInitiateCallHook({ message }) {
    const dispatch = useDispatch();
    const portal = useSelector((state) => state?.localPortal?.portal);

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';
            if (command === 'team-call-initiated' && message?.name === portal.portal_id) {
                dispatch(setCallInitiated(true));
                dispatch(setCallEnded(false));
            }

            if (command === 'team-call-initiation-error' && message?.name === portal.portal_id) {
                dispatch(setConnectionError(true));
            }

            if (command === 'redirect-waiting-screen' && message?.name === portal.portal_id) {
                dispatch(setWaitingScreen(true));
            }
        };

        handleCommand();
    }, [message, dispatch, portal]);
}
