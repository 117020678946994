import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPortalByOrgIdPortalId } from '../redux-toolkit/reducer/portalReducer';
import { getOrgDetailAPI } from '../redux-toolkit/reducer/orgReducer';

export default function useCmdFeatureFlagUpdated({ message }) {
    const dispatch = useDispatch();
    const portal = useSelector((state) => state?.localPortal?.portal);

    const handleFeatureFlageUpdated = async (commandData) => {
        if (portal?.org_id && portal.portal_id && commandData?.org_id === portal?.org_id) {
            dispatch(
                getPortalByOrgIdPortalId({
                    orgId: portal?.org_id,
                    portalId: portal.portal_id,
                }),
            );
            dispatch(getOrgDetailAPI({ orgId: commandData?.org_id }));
        }
    };

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';
            const commandData = message?.data?.data || {};
            if (command === 'feature-flag-updated') {
                await handleFeatureFlageUpdated(commandData);
            }
        };

        handleCommand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, dispatch]);
}
